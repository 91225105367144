<template >
  <b-container fluid class="container">
    <b-row align="center">
      <b-col >
        <h4 style="color:white; margin-bottom:40px; margin-top:15px; text-transform:capitalize;">
          <img style="margin-top:-7px;" height="20px;" :src="'../imgs/' + porRevisarInfo.icon" /> {{porRevisarInfo.nombreServiceInfo.toLowerCase()}}
        </h4>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col xl="8" lg="10" md="12" sm="12" cols="12">
        <b-card class="c-shadow-sm" style="margin-left:-5px; margin-right:-5px;">
          <b-row align="center">
            <b-col style="margin-bottom:20px;">
              <h5 style="color:#6262E8;"><b-icon font-scale=".8" icon="files"/> Trámites {{tagTipoInfo}} </h5>
            </b-col>
          </b-row>
          <panelTableTramites/>
          <b-row v-if="porRevisarInfo.tipo=='PENDIENTE PAGO'" align="center"><b-col><br><b-button @click="isShowModalNuevoTramite=true" pill class="btn-back-purple"><b-icon icon="folder-plus"/> Agregar trámite presencial</b-button></b-col></b-row>
          <b-row no-gutters align-h="end" align="right"><b-col  cols="12" ><span @click="getInfoRevisionTramite(porRevisarInfo.idServiceInfo, porRevisarInfo.tipo)" style="color:#6262E8; cursor:pointer;">Actualizar <b-icon font-scale=".9" icon="arrow-repeat" /></span></b-col></b-row>
        </b-card>
      </b-col>
    </b-row>    
    <b-modal title="Agregar trámite presencial" hide-footer v-model="isShowModalNuevoTramite">
      <b-row>
        <b-col cols="12">
          <b-input-group size="sm">
            <b-input-group-prepend is-text>
              <b-icon icon="person"></b-icon>
            </b-input-group-prepend>
            <b-form-input v-model="nombre" type="text" placeholder="Nombre(s) del ciudadano"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <b-input-group size="sm">
            <b-input-group-prepend is-text>
              <b-icon icon="person"></b-icon>
            </b-input-group-prepend>
            <b-form-input v-model="apellidoPat" type="text" placeholder="Apellido Paterno"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <b-input-group size="sm">
            <b-input-group-prepend is-text>
              <b-icon icon="person"></b-icon>
            </b-input-group-prepend>
            <b-form-input v-model="apellidoMat" type="text" placeholder="Apellido Materno"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <b-input-group size="sm">
            <b-input-group-prepend is-text>
              <b-icon icon="envelope"></b-icon>
            </b-input-group-prepend>
            <b-form-input v-model="correo" type="email" placeholder="Correo electrónico"></b-form-input>
          </b-input-group>
        </b-col>          
        <b-col cols="12">
          <b-input-group size="sm">
            <b-input-group-prepend is-text>
              <b-icon icon="phone"></b-icon>
            </b-input-group-prepend>
            <b-form-input v-model="telefono" type="number" placeholder="Teléfono 10 digitos"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <br>
          <h6>Tipo de persona</h6>
          <b-form-select v-model="tipoPersona" :options="optionsPersona" size="sm" ></b-form-select>
        </b-col>
        <br>
      </b-row>
      <b-row align="center"><b-col><br><b-button @click="agregarTramitePresencial" :disabled="!isFormTramitePresencialCompleto" pill class="btn-back-purple"><b-icon icon="folder-plus"/> Agregar</b-button></b-col></b-row>
    </b-modal>
  </b-container>
</template>

<script>
  import { mapState} from 'vuex'
  import panelTableTramites from './panelTableTramites.vue'
  import { agregarServicioPPAdmin, currentTramitesAdmin } from '@/axios-tools';

  export default {
    computed: {
      ...mapState({
        porRevisarInfo: state => state.admin.porRevisarInfo,
        token: state => state.admin.token
      }),
      tagTipoInfo:function(){
        var desc =  this.porRevisarInfo.tipo=='INICIADO'?'Iniciados':
                    this.porRevisarInfo.tipo=='POR REVISAR'?' Por Revisar':
                    this.porRevisarInfo.tipo=='PENDIENTE PAGO'?' Por Pagar':
                    this.porRevisarInfo.tipo=='PAGADO'?' Pagados':
                    this.porRevisarInfo.tipo=='POR ENTREGAR'?'Por Entregar':
                    this.porRevisarInfo.tipo=='CONCLUIDO'?'Concluidos':
                    this.porRevisarInfo.tipo=='CANCELADO'?'Cancelados':'';
        return desc;
      },
      isFormTramitePresencialCompleto: function(){
        return this.nombre!= null && this.nombre.length<51 &&
                this.apellidoPat!=null && this.apellidoPat.length<21 && 
                  this.apellidoMat !=null && this.apellidoMat.length<21 &&
                  this.correo !=null && this.correo.length<151 &&
                  (this.telefono==null || (this.telefono!=null&&this.telefono.length<11) )
      }
    },
    components: {
      panelTableTramites
    },
    data() {
      return {
        cliente: location.pathname.split("/", 2).toString().replace(",", ""),
        nombre:null,
        apellidoPat:null,
        apellidoMat:null,
        correo:null,
        telefono:null,
        isShowModalNuevoTramite:false,
        tipoPersona:'F',
        optionsPersona:[{value:'F', text:'Persona Física (Ciudadano)'}, {value:'M', text:'Representante Legal de Empresa'}, {value:'I', text:'Representante Legal de Institución Pública'}]
      }
    },
    created: function () {
      
    },
    methods: {
      getInfoRevisionTramite(idServiceInfo, tipo){
        currentTramitesAdmin(this.cliente, this.token, tipo, idServiceInfo, this.$router, this.$store, false);
      },
      limpiarFormAgregar(){
        this.nombre=null
        this.apellidoPat=null
        this.apellidoMat=null
        this.correo=null
        this.telefono=null
        this.tipoPersona='F'
      },
      agregarTramitePresencial(){
        agregarServicioPPAdmin(this.token, this.porRevisarInfo.idServiceInfo, this.nombre, this.apellidoPat, this.apellidoMat, this.correo, this.telefono, this.tipoPersona, this.$store, this.$router)
        this.limpiarFormAgregar();
        this.isShowModalNuevoTramite=false
      }
    },
    validations: {
        
    }
  }
</script>
<style>
.tipo-servicio{
  margin-top:10px;
}
</style>